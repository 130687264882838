<template>
  <div>
    <div @click="handleClick">
      <el-checkbox v-model="checked" disabled :class="checked ? 'select' : ''">
        <div>{{ statusText }}</div>
        <el-cascader
          v-model="selectValue"
          :options="phoneEffective"
          :props="{ expandTrigger: 'hover' }"
          @change="handleChange"
        ></el-cascader>
      </el-checkbox>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      type: Object,
      default: {},
    },
    markList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      statusId: "",
      statusText: "待拨打",
      selectValue: [],
      checked: false,
      phoneEffective: [],
    };
  },
  created() {
    console.log(1)
    console.log(this.markList)
    this.init(); // 电话列表初始化
  },
  watch: {
    row: {
    handler(newName, oldName) {
      this.init()
      }
    }
  },
  methods: {
    init() {
      // this.phoneEffective[this.phoneEffective.length - 1].children = this.markList
      const phoneEffectiveType = [
        // 四种电话类型字典
        {
          phoneKey: "memberPhone",
          phoneStatus: "memberPhoneStatus",
        },
        {
          phoneKey: "policePhone",
          phoneStatus: "policePhoneStatus",
        },
        {
          phoneKey: "thirdPhone",
          phoneStatus: "thirdPhoneStatus",
        },
        {
          phoneKey: "selfPhone",
          phoneStatus: "selfPhoneStatus",
        },
      ];
      const list = [], phoneEffective = []
      phoneEffectiveType.forEach(item => {
        if (this.row[item.phoneStatus] == 1) {
          if (list.indexOf(this.row[item.phoneKey]) != -1) {
            return
          }
          list.push(this.row[item.phoneKey])
          phoneEffective.push({
            value: this.row[item.phoneKey],
            label: this.row[item.phoneKey]
          })
        }
      })
      this.phoneEffective = phoneEffective
      this.phoneEffective.push({
        value: "notTouched4",
        label: "未触达",
        children: this.markList
      })
      if (this.row.callPhoneStatus == 1) {
      this.checked = true;
      this.handleFilterPhone(this.row.recoveryPhoneNumber);
      if (this.row.recoveryPhoneNumber) {
        // 已拨打过的电话
        this.statusText = this.row.recoveryPhoneNumber;
        // this.phoneEffective.pop();
      }
    } else {
      this.checked = false;
      this.statusText = "待拨打"
    }
    },
    handleClick() {
      console.log(0);
    },
    handleFilterPhone(data) {
      let seletItem = [],
        selectValue = [];
      seletItem = this.phoneEffective.filter((item) => {
        return data == item.value;
      });
      if (seletItem.length == 0) {
        seletItem = this.phoneEffective[
          this.phoneEffective.length - 1
        ].children.filter((item) => {
          return data == item.value;
        });
        if (seletItem.length) {
          selectValue.unshift(
            this.phoneEffective[this.phoneEffective.length - 1].value
          );
        }
      }
      if (seletItem.length) {
        selectValue.push(seletItem[0].value);
        this.statusId = seletItem[0].value;
        this.statusText = seletItem[0].label;
        this.selectValue = selectValue;
      }
    },
    handleChange(value) {
      // this.handleInitFilter(value)
      if (value.length == 0) return;
      let params = {
        manualOutboundCallId: this.row.manualOutboundCallId,
      };
      if (value.length > 1) {
        params.markType = value[value.length - 1];
      } else {
        params.phoneNumber = value[value.length - 1];
      }
      let opt = {
        method: "post",
        url: "/acb/2.0/manualOutboundCall/updateRecoveryPhone",
        data: params,
        success: (res) => {
          if (res.state == 0) {
            this.$emit("back", {});
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    handleInitFilter(data) {
      let seletItem = [];
      if (data.length == 1) {
        seletItem = this.phoneEffective.filter((item) => {
          return data[0] == item.value;
        });
      } else {
        const groupItem = this.phoneEffective.filter((item) => {
          return data[0] == item.value;
        });
        seletItem = groupItem[0].children.filter((item) => {
          return data[1] == item.value;
        });
      }
      // console.log(data)
      // console.log(seletItem)

      this.statusId = seletItem[0].value;
      this.statusText = seletItem[0].label;
      this.checked = true;
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/ {
  .el-checkbox__input.is-disabled .el-checkbox__inner {
    background-color: #FFF;
    border: 1px solid #DCDFE6;
    cursor: pointer;
  }

  .el-checkbox__input.is-disabled+span.el-checkbox__label {
    color: #2962FF;
    cursor: pointer;
  }

  .select .el-checkbox__input.is-disabled .el-checkbox__inner {
    background-color: #0f6eff;
    border-color: #0f6eff;
  }

  .el-checkbox__input.is-disabled .el-checkbox__inner::after {
    cursor: pointer;
  }

  .select .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #fff;
  }

  .el-checkbox__input.is-disabled .el-checkbox__inner {
    background-color: #fff;
    border: 1px solid #dcdfe6;
  }

  .el-cascader {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
}
</style>
