<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col-left">
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  size="12"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                  @change="handlePlateNumber"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.arrears')">
                <el-input
                  type="text"
                  v-model="formInline.minLackMoney"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mglr8">至</span>
                <el-input
                  type="text"
                  v-model="formInline.maxLackMoney"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mgl8">元</span>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Dial_status')">
                <el-select v-model.trim="formInline.callPhoneStatus" size="15">
                  <el-option
                    v-for="(item, index) in callList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <br />
              <el-form-item label="会员电话">
                <el-select v-model.trim="formInline.memberPhoneStatus" size="15">
                  <el-option
                    v-for="(item, index) in memberPhoneStatusList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_arrears_recovery')">
                <el-input
                  type="text"
                  v-model="formInline.minCallPhoneTimes"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mglr8">至</span>
                <el-input
                  type="text"
                  v-model="formInline.maxCallPhoneTimes"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mgl8">次</span>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Autonomous_parking')">
                <el-select v-model.trim="formInline.autoParkingStatus" size="15">
                  <el-option
                    v-for="(item, index) in memberPhoneStatusList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.security_interface_telephone')">
                <el-select v-model.trim="formInline.policePhoneStatus" size="15">
                  <el-option
                    v-for="(item, index) in memberPhoneStatusList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Recovery_number')">
                <el-input
                  type="text"
                  v-model="formInline.recoveryNo"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Retrieve_the_person_in_charge')">
                <el-input
                  type="text"
                  v-model="formInline.recoveryChargerName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                v-if="$route.meta.authority.button.query"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">清空</el-button>
            </div>
          </div>

          <div class="col_box_boder" v-if="$route.meta.authority.button.export"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                v-if="$route.meta.authority.button.export"
                @exportFile="handleExportFile"
                style="display: inline-block"
                :exportData="exportData"
              ></exportFile>
            </div>
          </div>
        </el-form>
      </div>
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            v-for="item in tableCols"
            :key="item.prop"
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'phone'">
                <table-phone
                  :phoneType="item.showParams.phoneType"
                  :isSuceess="scope.row[item.showParams.statusKey] == 1"
                  :phone="scope.row[item.prop]"
                  :tips="scope.row[item.showParams.textKey]"
                  :isPointer="true"
                  :isBack="true"
                  :row="scope.row"
                  @back="handlePhoneBack"
                />
              </div>
              <div v-else-if="item.prop == 'callPhoneStatus'">
                <table-call-status :row="scope.row" :markList="markList" @back="handleSearch" />
              </div>
              <div v-else-if="item.prop == 'remark'">
                <table-remarks :row="scope.row" @back="handleSearch" />
              </div>
              <div v-else>
                <p>
                  {{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)">欠费详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <arrearage-details
      v-if="detailShow"
      :detailShow="detailShow"
      :rowData="rowData"
      @close="detailShow = false"
    />
    <invalid-phone
      v-if="signDialogVisible"
      :signDialogVisible="signDialogVisible"
      :markList="markList"
      :successData="successData"
      @back="
        signDialogVisible = false;
        handleSearch();
      "
      @close="signDialogVisible = false"
    />
  </div>
</template>
<script>
import exportFile from "@/components/exportFile/exportFile.vue";
import arrearageDetails from "@/components/arrearage/arrearageDetails.vue";
import tablePhone from "@/components/tablePhone/tablePhone.vue";
import invalidPhone from "@/components/invalidPhone/invalidPhone.vue";
import tableCallStatus from "@/components/task/tableCallStatus/tableCallStatus.vue";
import tableRemarks from "@/components/task/tableRemarks/tableRemarks.vue";
import { compareSize } from "@/common/js/utils.js";
export default {
  data() {
    const callList = [
      {
        label: "全部",
        value: "",
      },
      {
        label: "待拨打",
        value: "0",
      },
      {
        label: "已拨打",
        value: "1",
      },
    ];
    return {
      loading: false,
      tableData: [],
      formInline: {
        plateNumber: "",
        carId: "",
        minLackMoney: "",
        maxLackMoney: "",
        callPhoneStatus: "0",
        memberPhoneStatus: "",
        minCallPhoneTimes: "",
        maxCallPhoneTimes: "",
        autoParkingStatus: "",
        policePhoneStatus: "",
        recoveryNo: "",
        recoveryChargerName: "",
      },
      exportData: {},
      callList: callList,
      memberPhoneStatusList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "有",
          value: "1",
        },
        {
          label: "无",
          value: "0",
        },
      ],
      selectList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "是",
          value: "1",
        },
        {
          label: "否",
          value: "0",
        },
      ],
      total: 20,
      pageSize: 15,
      pageNum: 1,
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "110",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "ownershipCodeName",
          label: "车辆归属地",
          width: "100",
        },
        {
          prop: "lackMoney",
          label: this.$t("list.arrears"),
          width: "",
          formatter: (row, column) => {
            if (row.lackMoney >= 0) {
              return Number(row.lackMoney / 100).toFixed(2) + "元";
            } else {
              return row.lackMoney + "元";
            }
          },
        },
        {
          prop: "callPhoneTimes",
          label: "电话追缴次数",
          width: "110",
        },
        {
          prop: "memberPhone",
          label: "会员电话",
          type: "phone",
          width: "150",
          showParams: {
            statusKey: "memberPhoneStatus",
            textKey: "memberPhoneInvalidRemark",
            phoneType: 1,
          },
        },
        {
          prop: "policePhone",
          label: "公安接口电话",
          type: "phone",
          width: "150",
          showParams: {
            statusKey: "policePhoneStatus",
            textKey: "policePhoneInvalidRemark",
            phoneType: 2,
          },
        },
        {
          prop: "thirdPhone",
          label: "三方导入电话",
          type: "phone",
          width: "150",
          showParams: {
            statusKey: "thirdPhoneStatus",
            textKey: "thirdPhoneInvalidRemark",
            phoneType: 3,
          },
        },
        {
          prop: "selfPhone",
          label: "近日自主停车",
          type: "phone",
          width: "150",
          showParams: {
            statusKey: "selfPhoneStatus",
            textKey: "selfPhoneInvalidRemark",
            phoneType: 4,
          },
        },
        {
          prop: "callPhoneStatus",
          label: "拨打状态",
          width: "160",
        },
        {
          prop: "remark",
          label: "备注",
          width: "200",
        },
        {
          prop: "callerName",
          label: "拨打人",
          width: "120",
        },
        {
          prop: "recoveryChargerName",
          label: "追缴负责人",
          width: "120",
        },
        {
          prop: "recoveryNo",
          label: "追缴编号",
          width: "140",
        },
      ],
      detailShow: false,
      rowData: {},
      successData: {
        id: "",
        phone: "",
        plateNumber: "",
      },
      signDialogVisible: false,
      markList: [],
      phoneList: [],
    };
  },
  async created() {
    this.handleExportFile();
    this.searchData();
    await this.handleGetMarkType();
    // this.handleGetPhoneType()  获取电话类型code，已写死在table，无法动态赋值
  },
  methods: {
    handlePlateNumber(value) {
      if (!value) {
        this.formInline.carId = "";
        this.pageNum = 1;
        this.searchData();
      }
    },
    handleSearch() {
      this.pageNum = 1;
      this.searchData();
    },
    searchData() {
      if (!this.formInline.carId) {
        this.formInline.plateNumber = "";
      }
      const { minLackMoney, maxLackMoney, minCallPhoneTimes, maxCallPhoneTimes } = this.formInline;
      if (!compareSize(minLackMoney, maxLackMoney, "欠费金额")) return;
      if (!compareSize(minCallPhoneTimes, maxCallPhoneTimes, "电话追缴次数")) return;
      this.loading = true;
      let opt = {
        method: "get",
        url: "/acb/2.0/manualOutboundCall/list",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline,
          minLackMoney: this.formInline.minLackMoney * 100 || "",
          maxLackMoney: this.formInline.maxLackMoney * 100 || "",
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    handleGetMarkType() {
      return new Promise((resolve, reject) => {
        let opt = {
          method: "get",
          url: "/acb/2.0/manualOutboundCall/selectInvalidPhoneReasonList",
          data: {},
          success: (res) => {
            if (res.state == 0) {
              const markList = [];
              const list = res.value || [];
              list.forEach((item) => {
                markList.push({
                  value: item.code,
                  label: item.desc,
                });
              });
              this.markList = markList;
              resolve();
            } else {
              this.$alert(res.msg, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              reject();
            }
          },
          fail: (err) => {
            this.$alert(err.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            reject();
          },
        };
        this.$request(opt);
      });
    },
    handleGetPhoneType() {
      let opt = {
        method: "get",
        url: "/acb/2.0/manualOutboundCall/selectPhoneTypeList",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.phoneList = res.value || [];
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    handleDetail(row) {
      this.rowData = row;
      this.detailShow = true;
    },
    handleExportFile() {
      let data = JSON.parse(JSON.stringify(this.formInline));
      // 导出数据
      this.exportData = {
        url: "/acb/2.0/manualOutboundCall/export",
        methods: "get",
        data: {
          name: "人工外呼",
          ...data,
        },
      };
    },
    resetForm() {
      this.formInline = {
        plateNumber: "",
        carId: "",
        minLackMoney: "",
        maxLackMoney: "",
        callPhoneStatus: "0",
        memberPhoneStatus: "",
        minCallPhoneTimes: "",
        maxCallPhoneTimes: "",
        autoParkingStatus: "",
        policePhoneStatus: "",
        recoveryNo: "",
        recoveryChargerName: "",
      };
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    handlePhoneBack(data) {
      if (data.type == "success") {
        this.handlePhoneSuccess(data.row, data.phone, data.phoneType);
      } else if (data.type == "error") {
        this.handlePhoneError(data.row, data.phone, data.phoneType);
      }
    },
    handlePhoneSuccess(row, phone, phoneType) {
      this.successData = {
        carId: row.carId,
        phone: phone,
        row: row,
        phoneType: phoneType,
      };
      this.signDialogVisible = true;
    },
    handlePhoneError(row, phone, phoneType) {
      this.$confirm("确认移除该无效电话号码？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleRequestError(row, phone, phoneType);
        })
        .catch(() => {});
    },
    handleRequestError(row, phone, phoneType) {
      let opt = {
        method: "post",
        url: "/acb/2.0/manualOutboundCall/removeInvalidPhone",
        data: {
          carId: row.carId,
          phoneNumber: phone,
          phoneType: phoneType,
          manualOutboundCallId: row.manualOutboundCallId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.$message({
              message: "移除成功",
              type: "success",
            });
            this.handleSearch();
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
      };
      this.$request(opt);
    },
  },
  components: {
    exportFile,
    tablePhone,
    arrearageDetails,
    invalidPhone,
    tableCallStatus,
    tableRemarks,
  },
};
</script>
<style lang="stylus" scoped>
.mglr8 {
  margin: 0 8px;
}

.mgl8 {
  margin-left: 8px;
}
</style>
