<template>
  <div>
    <img
      v-if="!inputShow"
      class="remarks-icon"
      src="../../../assets/img/remarks-icon.png"
      alt=""
      srcset=""
      @click="inputShow = true"
    />
    <span v-if="row.remark && !inputShow" class="remarks-box">{{
      row.remark
    }}</span>
    <el-input
      v-if="inputShow"
      v-model="remarksVal"
      placeholder="请输入备注"
      maxlength="10"
      @blur="handleSubmit"
    ></el-input>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      inputShow: false,
      remarksVal: "",
    };
  },
  created() {
    if (this.row.remark) {
      this.remarksVal = this.row.remark;
    }
  },
  methods: {
    handleSubmit() {
      if (!this.remarksVal) {
        this.inputShow = false;
        return;
      }
      if (!/^[\u4e00-\u9fa5]+$/i.test(this.remarksVal)) {
        this.$alert("只能输入汉字", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let opt = {
        method: "post",
        url: "/acb/2.0/manualOutboundCall/updateRemark",
        data: {
          manualOutboundCallId: this.row.manualOutboundCallId,
          remark: this.remarksVal,
        },
        success: (res) => {
          if (res.state == 0) {
            this.inputShow = false
            this.$emit("back", {});
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
  },
};
</script>
<style lang="stylus" scoped>
.remarks-icon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  cursor: pointer;
}

.remarks-box {
  vertical-align: middle;
  margin-left: 4px;
}

/deep/ {
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
</style>
