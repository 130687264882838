<template>
  <div v-if="signDialogVisible">
    <el-dialog
      title="标记无效电话"
      :visible.sync="signDialogVisible"
      width="30%"
      center
      :before-close="handleClose"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rule" label-width="100px" label-suffix=":">
          <el-form-item :label="$t('searchModule.plate_number')">
            <span>{{ successData.row.plateNumber }}</span>
          </el-form-item>
          <el-form-item :label="$t('searchModule.phone_number')">
            <span>{{ successData.phone }}</span>
          </el-form-item>
          <el-form-item label="上传录音文件">
            <el-upload
              ref="upLoad"
              class="avatar-uploader"
              accept=".mp3"
              action="/acb/2.0/manualOutboundCall/markInvalidPhone"
              :headers="uploadHeader"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="audioChange"
              :file-list="fileList"
              :http-request="uploadFile"
            >
              <div v-if="fileList.length > 0" class="avatar">
                {{ fileList[0].name }}
              </div>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            请上传MP3格式录音文件
          </el-form-item>
          <el-form-item :label="$t('searchModule.remarks')" prop="remarks">
             <el-select v-model="form.remarks" placeholder="请选择">
              <el-option v-for="(item, i) in markList" :key="i" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" class="close-btn">取 消</el-button>
        <el-button type="primary" @click="saveAddBlackFn" :loading="loading"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    signDialogVisible: {
      type: Boolean,
      default: false
    },
    successData: {
      type: Object,
      default: {}
    },
    markList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      uploadHeader: {
        "Session-Id": localStorage.token,
      },
      form: {
        remarks: ''
      },
      rule: {
        remarks: [
          { required: true, message: '请选择备注', trigger: 'change' }
        ]
      },
      remarksList: [
        {
          label: '投诉',
          value: '1'
        },
        {
          label: '多次拨打无人接听',
          value: '2'
        },
        {
          label: '空号',
          value: '3'
        },
        {
          label: '其他',
          value: '4'
        },
      ],
      fileList: [],
      loading: false,
      fileUrl: ''
    };
  },
  created() {
    console.log(this.markList)
  },
  methods: {
    handleClose() {
      this.$emit('close', {})
    },
    audioChange(file) {
      this.fileList = [];
      this.fileList.push(file);
    },
    uploadFile(file) {
      console.log("file0----", file);
      if (!file) {
        return;
      }
      let formData = new FormData();
      formData.append("carId", this.successData.carId);
      formData.append("manualOutboundCallId", this.successData.row.manualOutboundCallId);
      formData.append("phoneNumber", this.successData.phone);
      formData.append("markType", this.form.remarks);
      formData.append("phoneType", this.successData.phoneType);
      formData.append("image", file.file);
      const opt = {
        url: "/acb/2.0/manualOutboundCall/markInvalidPhone",
        method: "post",
        data: formData,
        config: {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq",
          },
        },
        success: (res) => {
          if (res.state == 0) {
            // this.fileUrl = res.url
            // this.handleRequest()
            this.handleClose()
            this.$emit('back', {})
          } else {
            this.$message({
              type: "error",
              message: "上传失败!",
            });
          }
        },
        fail: () => {
          this.loading = false
        },
      };
      this.$request(opt);
    },
    saveAddBlackFn() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.fileList.length) {
            this.$refs.upLoad.submit();
          } else {
            this.handleRequest()
          } 
        }
      })
    },
    handleRequest() {
      const params = {
        carId: this.successData.carId,
        manualOutboundCallId: this.successData.row.manualOutboundCallId,
        phoneNumber: this.successData.phone,
        markType: this.form.remarks,
        phoneType: this.successData.phoneType
        // phoneType: ''  // 待商议
      }
      const opt = {
        url: "/acb/2.0/manualOutboundCall/markInvalidPhone",
        method: "post",
        data: params,
        success: (res) => {
          this.loading = false
          if (res.state == 0) {
            this.$emit('back', {})
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        },
        fail: () => {
          this.loading = false
        }
      };
      this.$request(opt);
    }
  }
};
</script>
<style lang="stylus" scoped>
/deep/ {
  .close-btn {
    border-color: #527BFF;
    color: #527BFF;
  }
  .el-dialog__header {
    text-align: left;
  }
}
.avatar-uploader-icon {
  cursor: pointer;
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
</style>
